import {
  JSONEditor,
  Mode,
  TextContent,
  createAjvValidator,
  JSONSchema,
} from "vanilla-jsoneditor";
import { useEffect, useRef } from "react";
import styled from "styled-components";
import * as R from "rambda";

export interface JsonEditorProps {
  className?: string;
  content: string | undefined;
  onChange: (value: string | undefined) => void;
  schema?: JSONSchema;
}

export function JsonEditor(p: JsonEditorProps) {
  const refContainer = useRef<HTMLDivElement>(null);
  const refEditor = useRef<JSONEditor | null>(null);

  useEffect(() => {
    refEditor.current = new JSONEditor({
      target: refContainer.current!,
      props: {
        validator: p.schema
          ? createAjvValidator({
              // Remove $id field as it is not necessary for validation,
              // but may create conflicts with $schema
              schema: R.omit("$id", p.schema),
              ajvOptions: {
                validateSchema: false,
              },
            })
          : undefined,
        mode: Mode.text,
        mainMenuBar: false,
        navigationBar: false,
        content: {
          text: p.content ?? "",
        },
        onChange: (c, _, s) => {
          s.contentErrors === null && p.onChange((c as TextContent).text);
        },
      },
    });

    return () => {
      // destroy editor
      if (refEditor.current) {
        refEditor.current.destroy();
        refEditor.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (refEditor.current) {
      refEditor.current
        .update({
          text: p.content ?? "",
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e);
        });
    }
  }, [p.content]);

  return <Container className={p.className} ref={refContainer} />;
}

const Container = styled.div`
  min-height: 0;
  width: 100%;
  flex: 1;
`;
